export interface IConfig {
  CLIENT_ID: string;
  RADIX_ENVIRONMENT: string;
}

export function getClientId(): string {
  if (process.env.NODE_ENV === 'development') {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    if (!clientId) {
      throw Error('REACT_APP_CLIENT_ID missing from environment');
    }
    return clientId;
  } else {
    return getConfig('CLIENT_ID');
  }
}

export function getEnvironment() {
  return getConfig('RADIX_ENVIRONMENT') === 'production' ? 'production' : 'test';
}

export function getConfig(param: keyof IConfig): string {
  if (!window._env_ || Object.keys(window._env_).length === 0) {
    return '';
  }
  if (!window._env_[param]) {
    throw new Error('Missing required environment variable: ' + param);
  }

  return window._env_[param];
}
