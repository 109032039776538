import { tokens } from '@equinor/eds-tokens';
import { getEnvironment } from 'Environment/config';

type App = {
  name: string;
  description?: string;
  logo: string;
  backgroundColor: string;
  url: string;
  adRole: string;
};

const apps: App[] = [
  {
    name: 'PWEX',
    description: 'Petec Well Experience',
    logo: '/Logos/pwex.svg',
    // 8% energy red
    backgroundColor: `${tokens.colors.infographic.primary__energy_red_100.hex}14`,
    url:
      getEnvironment() === 'production'
        ? 'https://pwex.app.radix.equinor.com/'
        : 'https://pwex-client-pwex-staging.radix.equinor.com/',
    adRole: 'PWEX',
  },
  {
    name: 'ASAQC',
    description: 'Automated Seismic Acquisition QC',
    logo: '/Logos/asaqc_product_icon.svg',
    // 8% energy red
    backgroundColor: `${tokens.colors.infographic.primary__slate_blue.hex}14`,
    url: getEnvironment() === 'production' ? 'http://asaqc.equinor.com/' : 'https://vt-ngrmdf-qa.radix.equinor.com/',
    adRole: 'ASAQC',
  },
  {
    name: 'Dasha',
    description: 'Subsurface Data Sharing',
    logo: '/Logos/dasha.svg',
    backgroundColor: tokens.colors.infographic.primary__spruce_wood.hex,
    url:
      getEnvironment() === 'production'
        ? 'https://dasha.equinor.com/'
        : 'https://dasha-client-dasha-test.radix.equinor.com/',
    adRole: 'Dasha',
  },
  {
    name: 'Daily Production Dashboards',
    logo: '/Logos/dashboard.svg',
    backgroundColor: tokens.colors.infographic.primary__lichen_green.hex,
    url: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=591e2fef-63fa-443d-8031-bdb4b2fef60f&ctid=3aa4a235-b6e2-48d5-9195-7fcf05b459b0',
    adRole: 'ProductionDashboards',
  },
  {
    name: 'Foton',
    description: 'Insight into fiberoptics data',
    logo: '/Logos/foton.svg',
    backgroundColor: tokens.colors.infographic.primary__mist_blue.hex,
    url: 'https://fotone.equinor.com',
    adRole: 'Foton',
  },
  {
    name: 'Webviz-4D',
    description: 'Visualization of 4D attribute maps',
    logo: '/Logos/webviz4D.svg',
    backgroundColor: tokens.colors.infographic.primary__moss_green_100.hex,
    url: 'https://webviz-4d-johan-sverdrup.app.radix.equinor.com/',
    adRole: 'Webviz-4D',
  },
];

export default apps;
