import React from 'react';
import { AppBar } from 'Components/AppBar';
import { Toolbar, Container } from '@material-ui/core';
import { FullPageSpinner } from 'Components/FullPageSpinner';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'Components/Auth';
import AppGrid from 'Components/AppGrid';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  container: {
    flex: 1,
  },
});

function App() {
  const classes = useStyles();
  const { authState } = useAuth();

  return (
    <div className={classes.root}>
      <AppBar />
      <Toolbar />

      <Container maxWidth="md" className={classes.container}>
        {authState === 'authorized' ? <AppGrid /> : <FullPageSpinner />}
      </Container>
    </div>
  );
}

export default App;
