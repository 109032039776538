import { AuthenticationParameters, UserAgentApplication } from 'msal';
import { getClientId } from 'Environment/config';

export const fetchMsGraph = async (url: string, accessToken: string) => {
  return await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  BACKEND: getClientId() ?? '',
};

export const GRAPH_ENDPOINTS = {
  PHOTO: 'https://graph.microsoft.com/v1.0/me/photos/96x96/$value',
};

export const GRAPH_REQUESTS: { [key: string]: AuthenticationParameters } = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
    claimsRequest: JSON.stringify({ optionalClaims: { idToken: { name: 'given_name' } } }),
  },
  PHOTO: {
    scopes: [GRAPH_SCOPES.USER_READ],
  },
  BACKEND: {
    scopes: [GRAPH_SCOPES.BACKEND],
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: getClientId() ?? '',
    authority: 'https://login.microsoftonline.com/StatoilSRM.onmicrosoft.com/',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});
