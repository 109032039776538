import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  GridList,
  GridListTile,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Typography } from '@equinor/eds-core-react';
import { makeStyles } from '@material-ui/core/styles';
import apps from 'Config/apps';
import { tokens } from '@equinor/eds-tokens';
import { useAuth } from 'Components/Auth';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles<Theme, { cols: number }>(({ spacing }: Theme) => ({
  gridList: {
    paddingTop: spacing(2),
    justifyContent: ({ cols }) => (cols === 1 ? 'center' : 'flex-start'),
  },
  tile: {
    maxWidth: 480,
  },
  card: {
    margin: 4,
  },
  appCard: {
    minHeight: 326,
  },
  media: {
    height: 250,
    objectFit: 'contain',
  },
  subtitle: {
    color: tokens.colors.text.static_icons__tertiary.hex,
  },
}));

const AppGrid = () => {
  const { account } = useAuth();
  const { spacing, breakpoints } = useTheme();
  const xs = useMediaQuery(breakpoints.down('xs'));
  const sm = useMediaQuery(breakpoints.down('sm'));
  const cols = Math.min(xs ? 1 : sm ? 2 : 3, apps.length + 1);
  const classes = useStyles({ cols });

  const instructions = 'This page only displays applications that you have access to. To request access, please go to ';
  const accessItText = 'AccessIT';
  const accessItUrl = 'https://accessit.equinor.com/';
  const contactInstructions = '. If you are not in Equinor, contact your MC rep.';

  return (
    <GridList className={classes.gridList} cellHeight={334} cols={cols} spacing={spacing(2)}>
      {apps
        .filter((app) => account?.idTokenClaims?.roles?.includes(app.adRole))
        .map((app) => (
          <GridListTile key={app.url} className={classes.tile}>
            <Card className={classes.card}>
              <CardActionArea component="a" href={app.url} target="_blank" rel="noopener" className={classes.appCard}>
                <CardMedia
                  className={classes.media}
                  component="img"
                  title={app.name}
                  image={app.logo}
                  style={{ backgroundColor: app.backgroundColor }}
                />

                <CardContent>
                  <Typography variant="h5">{app.name}</Typography>
                  {app.description && (
                    <Typography variant="body_short" className={classes.subtitle}>
                      {app.description}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </GridListTile>
        ))}

      <GridListTile className={classes.tile}>
        <Card className={classes.card}>
          <Alert severity="info">
            <AlertTitle>Are you missing an app?</AlertTitle>
            {instructions}
            <a href={accessItUrl}>{accessItText}</a>
            {contactInstructions}
          </Alert>
        </Card>
      </GridListTile>
    </GridList>
  );
};

export default AppGrid;
